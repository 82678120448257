import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query';

const addAdvertisement = async (form) => {
  const { data } = await axios.post(`/api/advertisements/`, form);
  return data;
};

const addComment = async (form) => {
  const { data } = await axios.post(`/api/comments/`, form);
  return data;
};

const addMessage = async (form) => {
  const { data } = await axios.post(
    `/api/message/messages/holding/${form.holdingId}/`,
    form.data,
  );
  return data;
};

const addParcel = async (form) => {
  const { data } = await axios.post(
    `/api/v1/parcel/parcels/holding/${form.holdingId}/`,
    form.data,
  );
  return data;
};

const deleteDocument = async (id) => {
  const { data } = await axios.delete(`/api/documents/documents/${id}/`);
  return data;
};

const deleteParcel = async (form) => {
  const { data } = await axios.delete(
    `/api/v1/parcel/parcels/holding/${form.holdingId}/parcel/${form.parcelId}/`,
  );
  return data;
};

const login = async (form) => {
  const { data } = await axios.post(`/api/auth/login/`, form);
  return data;
};

const logout = async () => {
  const { data } = await axios.post(`/api/auth/logout/`);
  return data;
};

const passwordReset = async (form) => {
  const { data } = await axios.post(`/api/auth/password/reset/`, form);
  return data;
};

const passwordResetConfirm = async (form) => {
  const { data } = await axios.post(`/api/auth/password/reset/confirm/`, form);
  return data;
};

const passwordVerifyToken = async (form) => {
  const { data } = await axios.post(`/api/auth/password/verify-token/`, form);
  return data;
};

const updateAudit = async (form) => {
  const { data } = await axios.post(
    `/api/audit/audits/holding/${form.holdingId}/`,
    form.data,
  );
  return data;
};

const updateAutoDiag = async (form) => {
  const { data } = await axios.put(
    `/api/diagnosis/diagnoses/holding/${form.holdingId}/`,
    form.data,
  );
  return data;
};

const updateCounters = async (counter) => {
  const { data } = await axios.patch(`/api/user/page/${counter}/visit/`);
  return data;
};

const updateParcel = async (form) => {
  const { data } = await axios.put(
    `/api/v1/parcel/parcels/holding/${form.holdingId}/parcel/${form.parcelId}/`,
    form.data,
  );
  return data;
};

const updateHolding = async (form) => {
  const { data } = await axios.patch(
    `/api/v1/holding/holdings/${form.holdingId}/`,
    form.data,
  );
  return data;
};

const updatePlanning = async (form) => {
  const { data } = await axios.post(
    `/api/v1/parcel/parcels/planning/${form.holdingId}/year/${form.year}/`,
    form.data,
  );
  return data;
};

const updateSettings = async (form) => {
  const { data } = await axios.patch(`/api/settings/settings/`, form);
  return data;
};

const updateStepByStep = async (holdingId) => {
  const { data } = await axios.patch(
    `/api/v1/holding/holdings/${holdingId}/step-by-step/`,
    { step_by_step: true },
  );
  return data;
};

const updateUserProfile = async (form) => {
  const { data } = await axios.put(`/api/user/users/profile/`, form);
  return data;
};

export const uploadDocument = async (form) => {
  const { data } = await axios.post(`/api/documents/documents/`, form);
  return data;
};

export const useAddAdvertisementMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => addAdvertisement(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['advertisements']);
    },
  });
};

export const useAddCommentMutation = () =>
  useMutation((form) => addComment(form));

export const useAddMessageMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => addMessage(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['message']);
      queryClient.invalidateQueries(['messages']);
    },
  });
};

export const useAddParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => addParcel(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['planning']);
    },
  });
};

export const useDeleteDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteDocument(id), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents-received']);
      queryClient.invalidateQueries(['my-documents']);
    },
  });
};

export const useDeleteParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => deleteParcel(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['planning']);
    },
  });
};

export const useLoginMutation = () => useMutation((form) => login(form));

export const useLogoutMutation = () => useMutation(() => logout());

export const usePasswordResetMutation = () =>
  useMutation((form) => passwordReset(form));

export const usePasswordResetConfirmMutation = () =>
  useMutation((form) => passwordResetConfirm(form));

export const usePasswordVerifyTokenMutation = () =>
  useMutation((form) => passwordVerifyToken(form));

export const useUpdateAuditMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateAudit(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['holdings']);
      queryClient.invalidateQueries(['diagnosis']);
    },
  });
};

export const useUpdateAutoDiagMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateAutoDiag(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['diagnosis']);
      queryClient.invalidateQueries(['my-documents']);
    },
  });
};

export const useUpdateCountersMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((counter) => updateCounters(counter), {
    onSuccess: () => {
      queryClient.invalidateQueries(['counters']);
    },
  });
};

export const useUpdateHoldingMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateHolding(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['holding']);
    },
  });
};

export const useUpdateParcelMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateParcel(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['planning']);
    },
  });
};

export const useUpdatePlanningMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updatePlanning(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['planning']);
    },
  });
};

export const useUpdateSettingsMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateSettings(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['settings']);
    },
  });
};

export const useUpdateStepByStepMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((holdingId) => updateStepByStep(holdingId), {
    onSuccess: () => {
      queryClient.invalidateQueries(['holding']);
    },
  });
};

export const useUpdateUserProfileMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => updateUserProfile(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['current-user-profile']);
    },
  });
};

export const useUploadDocumentMutation = () => {
  const queryClient = useQueryClient();
  return useMutation((form) => uploadDocument(form), {
    onSuccess: () => {
      queryClient.invalidateQueries(['documents-received']);
      queryClient.invalidateQueries(['my-documents']);
    },
  });
};
