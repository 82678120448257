import { useEffect, useRef, useState } from 'react';
import introJs from 'intro.js';
import { jsPDF } from 'jspdf';

export const AUTODIAGSECTIONS = [
  'Identification',
  'Plan de masse',
  'Liste des aliments utilisables',
  'Presence OGM',
  'Services externes',
  'Traçabilité',
  'Synthèse VLOG',
  'Pâturage',
  'BEA',
];

export const CARBONENDSTEPLIST = ['DEROGATAIRE', 'PARCOURS TERMINE'];

export const MONTHS = [
  'janvier',
  'février',
  'mars',
  'avril',
  'mai',
  'juin',
  'juillet',
  'août',
  'septembre',
  'octobre',
  'novembre',
  'décembre',
];

export function handleWindowResize(setDevice) {
  let device;
  const resolution = window.innerWidth;

  if (resolution < 768) {
    device = 'mobile';
  } else if (resolution >= 768 && resolution <= 1024) {
    device = 'tablet';
  } else {
    device = 'desktop';
  }

  setDevice(device);
}

export const useVisible = (initialIsVisible) => {
  const [isVisible, setIsVisible] = useState(initialIsVisible);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return { ref, isVisible, setIsVisible };
};

export const getCookie = (name) => {
  // Split cookie string and get all individual name=value pairs in an array
  const cookieArr = document.cookie.split(';');

  // Loop through the array elements
  for (let i = 0; i < cookieArr.length; i += 1) {
    const cookiePair = cookieArr[i].split('=');

    /* Removing whitespace at the beginning of the cookie name
        and compare it with the given string */
    if (name === cookiePair[0].trim()) {
      // Decode the cookie value and return
      return decodeURIComponent(cookiePair[1]);
    }
  }

  // Return null if not found
  return null;
};

export function startTour() {
  introJs()
    .setOptions({
      doneLabel: 'Terminer',
      nextLabel: 'Suivant',
      overlayOpacity: 0.75,
      prevLabel: 'Précédent',
      skipLabel: '',
    })
    .start();
}

export const formatErrors = (error) => {
  let messages = [];
  const formattedErrors = {};
  const errorsObject = error.response.data;
  switch (error.response.status) {
    case 413:
      messages = ['Le fichier est trop volumineux.'];
      break;
    default:
      // eslint-disable-next-line no-restricted-syntax
      for (const property in errorsObject) {
        if (property === 'non_field_errors') {
          messages.push(...errorsObject[property]);
        } else {
          messages.push(errorsObject[property]);
          formattedErrors[property] = { message: errorsObject[property][0] };
        }
      }
  }
  return { formattedErrors, messages };
};

export const formatDate = (date) => {
  if (!date) return '';
  const newDate = new Date(date);
  return newDate.toLocaleString('fr-FR', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
};

export const addYears = (date, years) => {
  const newDate = new Date(date);
  newDate.setFullYear(newDate.getFullYear() + years);
  return newDate;
};

export const exportToPDF = (title, body, fileName) => {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF('portrait', 'mm', 'a4');
  doc.html(body, {
    callback(document) {
      document.text(title, 10, 15, {
        maxWidth: 190,
      });
      document.save(`${fileName}.pdf`);
    },
    x: 10,
    y: 40,
    width: 190,
    windowWidth: 595,
  });
};

export const handleAllCheck = (event, list) => {
  event.stopPropagation();

  if (event.target.checked) {
    const newSelectedIds = [];
    list.data.pages.forEach((page) => {
      page.results.forEach((holding) => newSelectedIds.push(holding.id));
    });
    return newSelectedIds;
  }
  return [];
};

export const handleCheck = (id, selectedIds) => {
  if (selectedIds.includes(id)) {
    return [...selectedIds.filter((selectedId) => selectedId !== id)];
  }
  return [...selectedIds, id];
};

export const formatToDjangoDateFormat = (date) => {
  let newDate = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  if (month <= 9) {
    newDate += `-0${month}`;
  } else {
    newDate += `-${month}`;
  }
  if (day <= 9) {
    newDate += `-0${day}`;
  } else {
    newDate += `-${day}`;
  }
  return newDate;
};

/**
 * getDays is a function that takes in three parameters:
 * 
 * @param {Date} date - The date to use as a starting point.
 * @param {Number} offset - The number of days offset from the date.
 * @param {String} target - The target date: 'month' or 'week'.
 * 
 * @returns {Array} An array of objects containing information about the days in the specified date range, including the day's name, number, formatted date, month number, and year.
 *
 * The function first sets up some variables: an array of names for the days of the week, a constant for the number of milliseconds in a day, and an empty array where the day information will be stored. The length variable is set depending on whether the target is a month or a week. The offset is set to a positive number if it is negative.

 * The start day is set to the first day of the month or the first day of the week, depending on the target. The loop then iterates over the length, adding an object containing the day's date, day label, day number, formatted date, month number, and year to the days array. The function then returns the days array.
 */
export const getDays = (date, offset, target) => {
  const name = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
  const DAY = 60 * 60 * 24 * 1000;
  const days = [];
  const length =
    target === 'month'
      ? new Date(
          date.getFullYear(),
          date.getMonth() + offset + 1,
          0,
          2,
        ).getDate()
      : 7;
  const newOffset = offset > 0 ? offset : offset * 7;
  date.setDate(date.getDate() + newOffset);
  let startDay = new Date(date.getFullYear(), date.getMonth(), 1, 2);
  if (target === 'week') {
    const simple = new Date(
      date.getFullYear(),
      0,
      1 + (date.getWeekNumber() - 1) * 7,
    );
    const dow = simple.getDay();
    startDay = simple;

    if (dow <= 4) startDay.setDate(simple.getDate() - simple.getDay() + 1);
    else startDay.setDate(simple.getDate() + 8 - simple.getDay());
  }
  for (let i = 0; i < length; i += 1) {
    const day = new Date(Date.parse(startDay) + DAY * i);
    days.push({
      date: day,
      dayLabel: name[i],
      dayNumber: day.getDate(),
      formattedDate: formatToDjangoDateFormat(day),
      monthNumber: day.getMonth() + 1,
      year: day.getFullYear(),
    });
  }
  return days;
};

export const getAllGrazedDays = (grazingLogs) => {
  let allGrazedDays = [];
  grazingLogs.forEach((parcel) => {
    allGrazedDays = [...new Set([...allGrazedDays, ...parcel.planning])];
  });
  return allGrazedDays;
};

export const getMonthlyGrazedDays = (planningData, month) => {
  let currentMonthGrazedDays = 0;
  getAllGrazedDays(planningData.grazing_logs).forEach((day) => {
    const parcelMonth = new Date(day).getMonth();
    if (month === parcelMonth) currentMonthGrazedDays += 1;
  });
  return `${currentMonthGrazedDays} jour${
    currentMonthGrazedDays > 1 ? 's' : ''
  }`;
};

// eslint-disable-next-line no-extend-native
Date.prototype.getWeekNumber = function getWeekNumber() {
  const d = new Date(
    Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()),
  );
  const dayNum = d.getUTCDay() || 7;
  d.setUTCDate(d.getUTCDate() + 4 - dayNum);
  const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
  return Math.ceil(((d - yearStart) / 86400000 + 1) / 7);
};

export const isAdmin = () => localStorage.getItem('role') === 'administrator';

export const isAuditor = () => localStorage.getItem('role') === 'auditor';

export const isBreeder = () => localStorage.getItem('role') === 'breeder';

export const Switch = ({ test, defaultComponent, children }) =>
  children.find((child) => child.props.value === test) || defaultComponent;

export const Case = ({ children }) => children;

export const setIframeImgSize = (index) => {
  document
    .querySelector(`#iframe-${index}`)
    .contentWindow.document.querySelector('img').style.margin = 'auto';
  document
    .querySelector(`#iframe-${index}`)
    .contentWindow.document.querySelector('img').style.maxWidth = '100%';
  document.querySelector(`#iframe-${index}`).height = document.querySelector(
    `#iframe-${index}`,
  ).contentWindow.document.body.scrollHeight;
};

export const getReferenceLabel = (referenceKey, references, source = '') => {
  let label = '';
  for (let index = 0; index < references.length; index += 1) {
    if (source === 'id' && references[index].id === referenceKey) {
      label = references[index].name;
    }
    if (source !== 'id' && Object.hasOwn(references[index], referenceKey)) {
      label = references[index][referenceKey];
    }
  }
  return label;
};

export const gapBetween2Dates = (date1, date2) => {
  const difference = date1.getTime() - date2.getTime();
  const totalDays = Math.ceil(difference / (1000 * 3600 * 24));
  const weeks = Math.round(totalDays / 7);
  const months = Math.round(totalDays / 31);
  const years = Math.round(totalDays / 365);
  if (totalDays >= 365) {
    return `Il y a ${years} an${years > 1 ? 's' : ''}`;
  }
  if (totalDays >= 31) {
    return `Il y a ${months} mois`;
  }
  if (totalDays >= 7) {
    return `Il y a ${weeks} semaine${weeks > 1 ? 's' : ''}`;
  }
  return `il y a ${totalDays} jour${totalDays > 1 ? 's' : ''}`;
};

export const getDocumentIdFromURL = (url) => url.split('/').at(-2);
