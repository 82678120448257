import React, { lazy } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { isAuditor } from '../lib/tools';
import PrivateRoute from './PrivateRoute';

const ActuPage = lazy(() => import('../pages/breeder/ActuPage'));
const ActusPage = lazy(() => import('../pages/breeder/ActusPage'));
const AdvertisementPage = lazy(
  () => import('../pages/breeder/AdvertisementPage'),
);
const AdvertisementsPage = lazy(
  () => import('../pages/breeder/AdvertisementsPage'),
);
const AgendaPage = lazy(() => import('../pages/breeder/AgendaPage'));
const AutoDiag = lazy(() => import('../components/AutoDiag'));
const AutoDiagPage = lazy(() => import('../pages/breeder/AutoDiagPage'));
const AutoDiagUpdatePage = lazy(
  () => import('../pages/breeder/AutoDiagUpdatePage'),
);
const BaseLayout = lazy(() => import('../layouts/BaseLayout'));
const DashboardPage = lazy(() => import('../pages/breeder/DashboardPage'));
const DocumentPage = lazy(() => import('../pages/breeder/DocumentPage'));
const DocumentsPage = lazy(
  () => import('../pages/administrator/DocumentsPage'),
);
const DocumentsReceivedHeader = lazy(
  () => import('../components/DocumentsReceivedHeader'),
);
const DocumentsReceivedList = lazy(
  () => import('../components/DocumentsReceivedList'),
);
const DocumentsReceivedPage = lazy(
  () => import('../pages/breeder/DocumentsReceivedPage'),
);
const EventPage = lazy(() => import('../pages/breeder/EventPage'));
const ForgotPasswordPage = lazy(() => import('../pages/ForgotPasswordPage'));
const HistoryPage = lazy(() => import('../pages/breeder/HistoryPage'));
const HistoryList = lazy(() => import('../components/HistoryList'));
const HoldingDetailPage = lazy(
  () => import('../pages/administrator/HoldingDetailPage'),
);
const HoldingSettings = lazy(() => import('../components/HoldingSettings'));
const HoldingsPage = lazy(() => import('../pages/administrator/HoldingsPage'));
const ImportantInfoPage = lazy(
  () => import('../pages/breeder/ImportantInfoPage'),
);
const LoginPage = lazy(() => import('../pages/LoginPage'));
const MessagePage = lazy(() => import('../pages/breeder/MessagePage'));
const MessagesList = lazy(() => import('../components/MessagesList'));
const MessagesPage = lazy(() => import('../pages/breeder/MessagesPage'));
const MyDocumentsHeader = lazy(() => import('../components/MyDocumentsHeader'));
const MyDocumentsList = lazy(() => import('../components/MyDocumentsList'));
const MyDocumentsPage = lazy(() => import('../pages/breeder/MyDocumentsPage'));
const MyTaskPage = lazy(() => import('../pages/breeder/MyTaskPage'));
const NoMatchPage = lazy(() => import('../pages/NoMatchPage'));
const PlanningPage = lazy(() => import('../pages/breeder/PlanningPage'));
const PlanningArea = lazy(() => import('../components/PlanningArea'));
const PlanningFooter = lazy(() => import('../components/PlanningFooter'));
const PlanningVolume = lazy(() => import('../components/PlanningVolume'));
const PlotPlanning = lazy(() => import('../components/PlotPlanning'));
const ResetPasswordPage = lazy(() => import('../pages/ResetPasswordPage'));
const SettingsPage = lazy(() => import('../pages/administrator/SettingsPage'));
const UserProfilePage = lazy(() => import('../pages/UserProfilePage'));
const VideosPage = lazy(() => import('../pages/breeder/VideosPage'));

export default function AppRoutes() {
  const { state } = useLocation();

  return (
    <Routes>
      <Route exact path='/login/' element={<LoginPage />} />
      <Route exact path='/forgot-password/' element={<ForgotPasswordPage />} />
      <Route
        exact
        path='/forgot-password/confirm/:uid/:token/'
        element={<ResetPasswordPage />}
      />
      <Route
        exact
        path='/'
        element={<Navigate to='/holding/dashboard' replace />}
      />
      <Route
        path='/holding/auto-diag/update/:page'
        element={
          <PrivateRoute>
            <AutoDiagUpdatePage />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/holding'
        element={<Navigate to='/holding/dashboard' replace />}
      />
      <Route
        path='/holding'
        element={
          <PrivateRoute>
            <BaseLayout />
          </PrivateRoute>
        }
      >
        <Route
          path='dashboard'
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path='important-infos'
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path='important-info/:id'
          element={
            <PrivateRoute>
              <ImportantInfoPage />
            </PrivateRoute>
          }
        />
        <Route
          path='tasks'
          element={
            <PrivateRoute>
              <DashboardPage />
            </PrivateRoute>
          }
        />
        <Route
          path='task/:id'
          element={
            <PrivateRoute>
              <MyTaskPage />
            </PrivateRoute>
          }
        />
        <Route exact path='auto-diag' element={<Navigate to='0' replace />} />
        <Route
          path='auto-diag/:page'
          element={
            <PrivateRoute>
              <AutoDiagPage />
            </PrivateRoute>
          }
        />
        <Route
          path='plot-planning'
          element={
            <PrivateRoute>
              <PlanningPage />
            </PrivateRoute>
          }
        />
        <Route
          path='my-documents'
          element={
            <PrivateRoute>
              <MyDocumentsPage />
            </PrivateRoute>
          }
        />
        <Route
          path='my-documents/:documentId'
          element={
            <PrivateRoute>
              <DocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path='documents-received'
          element={
            <PrivateRoute>
              <DocumentsReceivedPage />
            </PrivateRoute>
          }
        />
        <Route
          path='documents-received/:id'
          element={
            <PrivateRoute>
              <DocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path='messages'
          element={
            <PrivateRoute>
              <MessagesPage />
            </PrivateRoute>
          }
        />
        <Route
          path='messages/:id'
          element={
            <PrivateRoute>
              <MessagePage />
            </PrivateRoute>
          }
        />
        <Route
          path='history'
          element={
            <PrivateRoute>
              <HistoryPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path='/actus'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={false} />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute>
              <ActusPage />
            </PrivateRoute>
          }
        />
        <Route
          path=':id'
          element={
            <PrivateRoute>
              <ActuPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path='/agenda'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={false} />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute>
              <AgendaPage />
            </PrivateRoute>
          }
        />
        <Route
          path=':id'
          element={
            <PrivateRoute>
              <EventPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path='/advertisements'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={false} />
          </PrivateRoute>
        }
      >
        <Route
          index
          element={
            <PrivateRoute>
              <AdvertisementsPage />
            </PrivateRoute>
          }
        />
        <Route
          path=':id'
          element={
            <PrivateRoute>
              <AdvertisementPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path='/videos'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={false}>
              <VideosPage />
            </BaseLayout>
          </PrivateRoute>
        }
      />
      <Route
        exact
        path='/admin'
        element={<Navigate to='/admin/holdings' replace />}
      />
      <Route
        path='/admin'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={!isAuditor()} />
          </PrivateRoute>
        }
      >
        <Route path='/admin/holdings'>
          <Route
            index
            element={
              <PrivateRoute admin auditor>
                <HoldingsPage />
              </PrivateRoute>
            }
          />
          <Route
            path=':id'
            element={
              <PrivateRoute admin auditor>
                <HoldingDetailPage />
              </PrivateRoute>
            }
          >
            <Route
              path='history'
              element={
                <PrivateRoute admin>
                  <HistoryList />
                </PrivateRoute>
              }
            />
            <Route
              path='documents'
              element={
                <PrivateRoute admin auditor>
                  <MyDocumentsHeader />
                  <MyDocumentsList />
                </PrivateRoute>
              }
            />
            <Route
              path='documents-apbo'
              element={
                <PrivateRoute admin>
                  <DocumentsReceivedHeader />
                  <DocumentsReceivedList />
                </PrivateRoute>
              }
            />
            <Route
              path='planning'
              element={
                <PrivateRoute admin auditor>
                  <PlotPlanning />
                  <PlanningVolume />
                  <PlanningArea />
                  <PlanningFooter />
                </PrivateRoute>
              }
            />
            <Route
              exact
              path='auto-diag'
              element={<Navigate to='0' state={state} replace />}
            />
            <Route
              path='auto-diag/:page'
              element={
                <PrivateRoute admin auditor>
                  <AutoDiag />
                </PrivateRoute>
              }
            />
            <Route
              path='messages'
              element={
                <PrivateRoute admin>
                  <MessagesList />
                </PrivateRoute>
              }
            />
            <Route
              path='messages/:id'
              element={
                <PrivateRoute admin>
                  <MessagePage />
                </PrivateRoute>
              }
            />
            <Route
              path='messages/:id/:documentId'
              element={
                <PrivateRoute admin>
                  <DocumentPage />
                </PrivateRoute>
              }
            />
            <Route
              path='settings'
              element={
                <PrivateRoute admin>
                  <HoldingSettings />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
        <Route
          exact
          path='documents'
          element={
            <PrivateRoute admin>
              <DocumentsPage />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path='documents/:id'
          element={
            <PrivateRoute admin>
              <DocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path='messages'
          element={
            <PrivateRoute admin>
              <MessagesPage />
            </PrivateRoute>
          }
        />
        <Route
          path='messages/:id'
          element={
            <PrivateRoute admin>
              <MessagePage />
            </PrivateRoute>
          }
        />
        <Route
          path='messages/:id/:documentId'
          element={
            <PrivateRoute admin>
              <DocumentPage />
            </PrivateRoute>
          }
        />
        <Route
          path='settings'
          element={
            <PrivateRoute admin>
              <SettingsPage />
            </PrivateRoute>
          }
        />
      </Route>
      <Route
        path='/profile'
        element={
          <PrivateRoute>
            <BaseLayout hasLeftMenu={!isAuditor()}>
              <UserProfilePage />
            </BaseLayout>
          </PrivateRoute>
        }
      />
      <Route
        path='*'
        element={
          <PrivateRoute>
            <NoMatchPage />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}
